<template>
  <main-layout>
    <main class="section is-medium uninstall">
      <div class="container">
        <div class="box">
          <div class="columns is-gapless">
            <div class="column">
              <div class="uninstall__left has-content-centered has-text-centered">
                <h1 class="uninstall__title is-size-2 has-text-weight-black">
                  <span class="has-text-secondary" v-t="`uninstall.oh-no`"></span>
                  <span v-t="`uninstall.feel-sorry`"></span>
                </h1>
                <p class="has-text-grey is-size-6">
                  <span v-html="$t('uninstall.help-us')"></span>
                </p>
                <div :class="'uninstall__icon_' + locale"></div>
              </div>
            </div>

            <div class="column uninstall__right">
              <div class="uninstall__form">
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLScJzPAEJLOeB4UYT5FU1f0xi2t-XSN3zasYXECJ9KZQp681Rg/viewform?embedded=true"
                  width="100%"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </main-layout>
</template>

<script>
import { ga, mixpanel } from '@/core/services/Analytics'
import Locator from '@/core/services/Locator'
import config from '@/config'

export default {
  mounted () {
    // Remove info about pouch being installed.
    localStorage.removeItem('pouchInstalled')

    // Remove the flag which shows user is signed-in and has the extension installed.
    sessionStorage.removeItem('extensionSignedIn')

    // If a user is redirected here from extension,
    // sends an uninstall event to their profile.
    const uninstallData = new URL(window.location.href.replace(/&amp;/g, '&')).searchParams.get('id')

    if (uninstallData) {
      this.sendUninstallEvent(uninstallData)
    }
  },

  computed: {
    locale () {
      return Locator.translation
    }
  },

  methods: {
    sendUninstallEvent (data) {
      const browser = this.$browser.name

      // Prepares the properties received from extension.
      const properties = [
        passable => decodeURIComponent(passable),
        passable => atob(passable),
        passable => JSON.parse(passable),
        passable => ({
          customerHash: passable.distinct_id,
          previousVersion: 'n/a',
          currentVersion: passable.extension_version,
          userAction: 'uninstall',
          $time: new Date().toISOString(),
          distinct_id: passable.distinct_id,
          // First letter of a browser name should be capital.
          $browser: browser.charAt(0).toUpperCase() + browser.substr(1)
        })
      ].reduce((carry, pipe) => pipe(carry), data)

      // Sends an event to the custom tracking lambda.
      this.$config.apiEndpoints.uninstallTracking.post({}, properties)

      // Identifies the extension user and sends an uninstall event
      // with their distinct_id to mixpanel.
      mixpanel.identify(properties.distinct_id)
      mixpanel.event('Extension Status', properties)

      // Extracts GA event data from properties and formats as label
      const { currentVersion, customerHash, previousVersion } = properties
      const label = Object.keys({
        currentVersion, customerHash, previousVersion
      }).map(key => `${key}:${properties[key]}`).join(' - ')

      // Creates custom GA tracker object for uninstall and dispatches event
      ga.create(config.analytics.tokens.extGa, 'extensionTracker')
      ga.name('extensionTracker')
        .event('Extension Status', 'userAction:uninstall', label)
    }
  }
}
</script>
